<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { Cancel } from '@/constants/modalButtons.const';
import {
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { scopes as CHARGE_POSTS_SCOPES } from '../store/ChargePostsModule';

export default {
  name: 'ChargePostStatusForm',
  components: {
    ContentCellComponent,
    StoreNotificationComponent,
    GenericModalComponent,
    MuiTextarea,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  props: {
    chargePost: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isLoading: false,
      inputs: {
        active: this.chargePost.active,
        comment: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.chargePosts, {
      newChargePostStatus: state => state.newChargePost.STATUS,
    }),
    isStatusChanged() {
      return this.inputs.active !== this.chargePost.active;
    },
    modalButtons() {
      return [
        {
          ...Cancel,
          listeners: {
            click: () => this.$emit('closeModal'),
          },
        },
        {
          attributes: {
            form: 'chargePostStatusForm',
            type: 'submit',
            loading: this.isLoading,
            disabled: !this.isFormValid,
          },
          text: 'Save',
        },
      ];
    },
  },
  created() {
    this.CHARGE_POSTS_SCOPES = CHARGE_POSTS_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.clearErrors();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.chargePosts, [
      'putChargePostStatus',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleet.chargePosts, [
      'resetData',
      'clearErrors',
    ]),
    async request() {
      this.isLoading = true;
      await this.putChargePostStatus({
        chargePostUuid: this.chargePost.uuid,
        data: this.inputs,
      });
      this.isLoading = false;
      if (!this.newChargePostStatus.ERROR) {
        this.resetData();
        this.$notify({
          message: 'Charge post status edited successfully!',
          textAction: '',
        });
        this.onSuccess();
        this.$emit('closeModal');
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.small"
    :buttons="modalButtons"
    title="Edit charge post status"
    class="ChargePostStatusForm"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.chargePosts"
        :scope="CHARGE_POSTS_SCOPES.newChargePost"
        is-editing
        element="charge post status"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        id="chargePostStatusForm"
        :extra-conditions="[isStatusChanged]"
        @areAllValid="valid => isFormValid = valid"
        @submit="request"
      >
        <ContentCellComponent
          label="Charge post status"
          class="mb-4"
        >
          <ui-toggle
            :value="inputs.active"
            :text="inputs.active ? 'Active' : 'Inactive'"
            data-test-id="active-toggle"
            name="active"
            @changevalue="({ detail }) => inputs.active = detail"
          />
        </ContentCellComponent>
        <MuiTextarea
          v-model="inputs.comment"
          v-validate="{
            isRequired: true,
            isMinLength: {
              message: 'We need at least 6 characters',
              length: 6,
            }
          }"
          :disabled="!isStatusChanged"
          label="Comments*"
          name="comments"
          placeholder="Reason for changing the charge post status"
          rows="4"
        />
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
