var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "ChargePostsListView",
      attrs: { "data-test-id": "charge_posts_list-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Charge posts ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create_charge_post-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Create new charge post ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "chargePosts",
            attrs: {
              filters: _vm.getOperatorFilter({ attribute: "operator.id" }),
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.exportColumns,
              facets: _vm.facets,
              labels: {
                searchTable: "Search by Id, model, city...",
              },
              "table-config": _vm.contentCells,
              index: _vm.ALGOLIA_INDEXES.chargePosts,
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("ChargePostForm", {
            attrs: {
              "charge-post-uuid": _vm.uuidToEdit,
              callback: _vm.onFormSuccess,
              "data-test-id": "charge_post-form",
            },
            on: { closeModal: _vm.closeModal, "modal-closed": _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("DeleteChargePost", {
            attrs: {
              "charge-post": _vm.chargePost,
              callback: _vm.onFormSuccess,
              "data-test-id": "delete_charge_post",
            },
            on: { closeModal: _vm.closeModal, "modal-closed": _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }