<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CommentsList } from '@/components';

export default {
  name: 'ChargePostComments',
  components: {
    CommentsList,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.chargePosts, {
      chargePost: state => state.detail.data,
      chargePostStatus: state => state.detail.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      internalRemarks: state => state.general.data.internal_remarks,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.chargePosts, [
      'getChargePost',
    ]),
    async getCommentsByUuid() {
      await this.getChargePost(this.chargePost.uuid);
    },
  },
};
</script>

<template>
  <CommentsList
    :comments="chargePost.comments"
    :is-loading="chargePostStatus.LOADING"
    :entity-uuid="chargePost && chargePost.uuid"
    :callback="getCommentsByUuid"
    entity="charge_post"
  />
</template>
