<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    chargePost: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    text() {
      const locationText = ` - ${get(this, 'chargePost.city.name')} - ${get(this, 'chargePost.location.name')}`;
      return `${get(this, 'chargePost.model.fullName')} (Id ${get(this, 'chargePost.id')}) ${locationText}`;
    },
    ...mapState(DOMAINS_MODEL.fleet.chargePosts, {
      deleteChargePostStatus: state => state.deleteChargePost.STATUS,
    }),
  },
  methods: {
    async removeChargePost() {
      await this.deleteChargePost(this.chargePost.uuid);
      this.$emit('closeModal');
      if (!this.deleteChargePostStatus.ERROR) {
        this.$notify({
          message: 'Charge post deleted successfully!',
          textAction: '',
        });
        this.callback();
      }
    },
    ...mapActions(DOMAINS_MODEL.fleet.chargePosts, [
      'deleteChargePost',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteChargePost"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="text"
        title="Are you sure you want to delete this charge post?"
      />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteChargePostStatus.LOADING"
        @click="removeChargePost"
      />
    </template>
  </GenericModalComponent>
</template>
