var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CommentsList", {
    attrs: {
      comments: _vm.chargePost.comments,
      "is-loading": _vm.chargePostStatus.LOADING,
      "entity-uuid": _vm.chargePost && _vm.chargePost.uuid,
      callback: _vm.getCommentsByUuid,
      entity: "charge_post",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }