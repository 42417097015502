<script>
import { mapActions, mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import {
  PageTabs,
  PageView,
} from '@/components';
import fleetRoutes from '../../router/FleetRouterMap';

export default {
  name: 'ChargePostsDetailView',
  components: {
    PageView,
    PageTabs,
  },
  props: {
    chargePostUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.chargePosts, {
      chargePost: state => state.detail.data,
    }),
  },
  watch: {
    chargePostUuid: {
      async handler() {
        await this.fetchChargePost();
      },
      immediate: true,
    },
  },
  created() {
    this.tabs = [
      {
        label: {
          title: 'Setup',
        },
        url: fleetRoutes.chargePosts.detail.setup,
      },
      {
        label: {
          title: 'Comments',
        },
        url: fleetRoutes.chargePosts.detail.comments,
      },
    ];
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.chargePosts, [
      'getChargePost',
    ]),
    async fetchChargePost() {
      this.isLoading = true;
      await this.getChargePost(this.chargePostUuid);
      this.isLoading = false;

      if (!this.chargePost) {
        this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      }
    },
  },
};
</script>

<template>
  <div
    :class="[
      'ChargePostDetailView d-flex flex-column flex-fill',
      {
        'justify-content-center': isLoading,
      }
    ]"
  >
    <ui-loader
      v-if="isLoading"
      label="Loading charge post..."
    />
    <template v-else-if="chargePost">
      <PageTabs :tabs="tabs" />
      <PageView is-tab-content>
        <RouterView @refreshChargePost="fetchChargePost" />
      </PageView>
    </template>
  </div>
</template>
