<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import values from 'lodash/values';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent, StoreNotificationComponent } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import AlgoliaOptionsComponent from '../../VehicleModels/components/AlgoliaOptionsComponent';
import { INSTALLATION_TYPES } from '../const/index';
import { scopes as CHARGE_POSTS_SCOPES } from '../store/ChargePostsModule';

export default {
  name: 'ChargePostForm',
  components: {
    AlgoliaOptionsComponent,
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
    StoreNotificationComponent,
  },
  props: {
    chargePostUuid: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      initialized: false,
      originalInputs: null,
      inputs: {
        modelUuid: null,
        serialNumber: null,
        chargeTypes: [],
        installationType: null,
        operatorUuid: null,
        cityUuid: null,
        locationUuid: null,
      },
    };
  },
  computed: {
    modalTitle() {
      if (this.initialized) {
        return this.chargePostUuid
          ? `Edit ${get(this, 'chargePost.model.fullName')} (Id ${get(this, 'chargePost.id')})`
          : 'Add charge post';
      }

      return '';
    },
    areRequiredFieldsFilled() {
      return this.inputs.modelUuid
        && this.inputs.serialNumber
        && this.inputs.chargeTypes.length
        && this.inputs.installationType
        && this.inputs.operatorUuid
        && this.inputs.cityUuid
        && this.inputs.locationUuid;
    },
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => state.operators.active.uuid,
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.cities, {
      citiesData: state => state.data,
      citiesStatus: state => state.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.fleet.chargePosts, {
      newChargePostStatus: state => state.newChargePost.STATUS,
      newChargePost: state => state.newChargePost.data,
      chargePost: state => state.detail.data,
    }),
  },
  async created() {
    this.clearErrors();
    this.CHARGE_POSTS_SCOPES = CHARGE_POSTS_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.INSTALLATION_TYPES = INSTALLATION_TYPES;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.sentenceCase = sentenceCase;
    this.inputs.operatorUuid = this.activeOperatorUuid;
    if (this.chargePostUuid) {
      await this.getChargePost(this.chargePostUuid);
      this.inputs.modelUuid = get(this, 'chargePost.model.uuid');
      this.inputs.serialNumber = get(this, 'chargePost.serialNumber');
      this.inputs.chargeTypes = get(this, 'chargePost.model.uuid');
      this.inputs.chargeTypes = (get(this, 'chargePost.chargeTypes') || []).map(chargeType => chargeType.uuid);
      this.inputs.installationType = get(this, 'chargePost.installationType');
      this.inputs.operatorUuid = get(this, 'chargePost.operator.uuid');
      this.inputs.cityUuid = get(this, 'chargePost.city.uuid');
      this.inputs.locationUuid = get(this, 'chargePost.location.uuid');
      this.originalInputs = cloneDeep(this.inputs);
    }
    this.initialized = true;
  },
  methods: {
    map,
    values,
    async request() {
      this.clearErrors();
      const request = this.chargePostUuid
        ? this.putChargePost
        : this.postChargePost;
      const params = this.chargePostUuid
        ? { chargePostUuid: this.chargePostUuid, data: this.inputs }
        : this.inputs;
      await request(params);

      if (!this.newChargePostStatus.ERROR) {
        const { id } = this.newChargePost;
        const message = this.chargePostUuid
          ? 'Charge post edited successfully!'
          : `Charge post added successfully with <span class="emobg-font-weight-bold">Id ${id}.</span>`;
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.callback(id);
        this.resetData();
      }
    },
    ...mapActions(DOMAINS_MODEL.fleet.chargePosts, [
      'getChargePost',
      'postChargePost',
      'putChargePost',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleet.chargePosts, [
      'resetData',
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    class="ChargePostForm"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.chargePosts"
        :scope="CHARGE_POSTS_SCOPES.newChargePost"
        :is-editing="!!chargePostUuid"
        element="charge post"
      />
    </template>
    <template slot="body">
      <div class="row">
        <div class="col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none">
          <div class="pr-2">
            <MuiAlgoliaSelect
              v-if="initialized"
              v-model="inputs.modelUuid"
              :title="model => `${model.manufacturer} ${model.name}`"
              :index="ALGOLIA_INDEXES.chargePostModels"
              label="Charge post model*"
              name="model"
              no-cache
              path-value="uuid"
              placeholder="Select a charge post model"
              class="w-100 mb-4"
            />
            <ui-skeleton
              v-else
              class="mb-4"
            />
            <MuiInputText
              v-if="initialized"
              v-model="inputs.serialNumber"
              label="Serial number*"
              placeholder="Enter a serial number"
              name="serialNumber"
              class="w-100 mb-4"
            />
            <ui-skeleton
              v-else
              class="mb-4"
            />

            <div
              v-if="initialized"
              class="mb-4"
            >
              <label class="d-block emobg-font-weight-semibold mb-1">
                Supported charge types*
              </label>
              <AlgoliaOptionsComponent
                v-model="inputs.chargeTypes"
                :label="option => option.name"
                :index="ALGOLIA_INDEXES.chargeTypes"
                path-value="uuid"
              />
            </div>

            <label class="d-block emobg-font-weight-semibold mb-1">Installation type options*</label>
            <ui-radio
              v-for="type in values(INSTALLATION_TYPES)"
              :key="type"
              :value="inputs.installationType"
              :option="type"
              :caption="sentenceCase(type)"
              class="d-block mb-2"
              name="installationType"
              @changevalue="() => inputs.installationType = type"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="pl-2">
            <div class="mb-4">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Allocate charge post
              </label>
              <p class="emobg-caption-1 emobg-color-ink-light">
                Select the next fields to allocate the charge post to a specific location.
              </p>
              <p class="emobg-caption-1 emobg-color-ink-light">
                Charge posts cannot be assigned to Geofence locations.
              </p>
            </div>

            <MuiAlgoliaSelect
              v-if="initialized"
              v-model="inputs.operatorUuid"
              :title="result => result.name"
              :filters="`id:${activeOperatorId} OR parent_cs_operator_id:${activeOperatorId}`"
              :index="ALGOLIA_INDEXES.csOperators"
              label="Operator*"
              name="operator"
              no-cache
              path-value="uuid"
              placeholder="Select an operator"
              class="w-100 mb-4"
            />
            <ui-skeleton
              v-else
              class="mb-4"
            />

            <MuiSelect
              v-if="initialized && citiesStatus.LOADED"
              v-model="inputs.cityUuid"
              :options="map(citiesData, city => ({label: city.name, value: city.uuid }))"
              name="city"
              placeholder="Select a city"
              label="City*"
              class="w-100 mb-4"
            />
            <ui-skeleton v-if="citiesStatus.LOADING" />

            <MuiAlgoliaSelect
              v-if="initialized && citiesStatus.LOADED"
              v-model="inputs.locationUuid"
              :title="result => result.name"
              :filters="`city_uuid:${inputs.cityUuid} AND NOT type: geofence`"
              :disabled="!inputs.cityUuid"
              :index="ALGOLIA_INDEXES.locations"
              label="Location*"
              name="location"
              no-cache
              path-value="uuid"
              placeholder="Select a location"
              class="w-100"
            />
            <ui-skeleton
              v-else
              class="mb-4"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <div class="d-flex justify-content-center">
          <CancelButton @click="$emit('closeModal')" />
        </div>

        <div class="d-flex justify-content-center">
          <ui-button
            :disabled="!areRequiredFieldsFilled || hasSameValues"
            :loading="newChargePostStatus.LOADING"
            class="wmin-initial"
            @clickbutton="request"
          >
            Save
          </ui-button>
        </div>
      </div>
    </template>
  </GenericModalComponent>
</template>
<style lang="scss">
  .ChargePostForm {
    .v1-MuiInputText__label-text {
      padding: 0 !important;
    }
  }
</style>
