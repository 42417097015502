var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ChargePostStatusForm",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.small,
          buttons: _vm.modalButtons,
          title: "Edit charge post status",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.chargePosts,
                    scope: _vm.CHARGE_POSTS_SCOPES.newChargePost,
                    "is-editing": "",
                    element: "charge post status",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    attrs: {
                      id: "chargePostStatusForm",
                      "extra-conditions": [_vm.isStatusChanged],
                    },
                    on: {
                      areAllValid: (valid) => (_vm.isFormValid = valid),
                      submit: _vm.request,
                    },
                  },
                  [
                    _c(
                      "ContentCellComponent",
                      {
                        staticClass: "mb-4",
                        attrs: { label: "Charge post status" },
                      },
                      [
                        _c("ui-toggle", {
                          attrs: {
                            value: _vm.inputs.active,
                            text: _vm.inputs.active ? "Active" : "Inactive",
                            "data-test-id": "active-toggle",
                            name: "active",
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.inputs.active = detail),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("MuiTextarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                            isMinLength: {
                              message: "We need at least 6 characters",
                              length: 6,
                            },
                          },
                          expression:
                            "{\n          isRequired: true,\n          isMinLength: {\n            message: 'We need at least 6 characters',\n            length: 6,\n          }\n        }",
                        },
                      ],
                      attrs: {
                        disabled: !_vm.isStatusChanged,
                        label: "Comments*",
                        name: "comments",
                        placeholder:
                          "Reason for changing the charge post status",
                        rows: "4",
                      },
                      model: {
                        value: _vm.inputs.comment,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "comment", $$v)
                        },
                        expression: "inputs.comment",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }