var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ChargePostForm",
        attrs: { title: _vm.modalTitle, header: { isClosable: true } },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.fleet.chargePosts,
              scope: _vm.CHARGE_POSTS_SCOPES.newChargePost,
              "is-editing": !!_vm.chargePostUuid,
              element: "charge post",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none",
            },
            [
              _c(
                "div",
                { staticClass: "pr-2" },
                [
                  _vm.initialized
                    ? _c("MuiAlgoliaSelect", {
                        staticClass: "w-100 mb-4",
                        attrs: {
                          title: (model) =>
                            `${model.manufacturer} ${model.name}`,
                          index: _vm.ALGOLIA_INDEXES.chargePostModels,
                          label: "Charge post model*",
                          name: "model",
                          "no-cache": "",
                          "path-value": "uuid",
                          placeholder: "Select a charge post model",
                        },
                        model: {
                          value: _vm.inputs.modelUuid,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "modelUuid", $$v)
                          },
                          expression: "inputs.modelUuid",
                        },
                      })
                    : _c("ui-skeleton", { staticClass: "mb-4" }),
                  _vm.initialized
                    ? _c("MuiInputText", {
                        staticClass: "w-100 mb-4",
                        attrs: {
                          label: "Serial number*",
                          placeholder: "Enter a serial number",
                          name: "serialNumber",
                        },
                        model: {
                          value: _vm.inputs.serialNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "serialNumber", $$v)
                          },
                          expression: "inputs.serialNumber",
                        },
                      })
                    : _c("ui-skeleton", { staticClass: "mb-4" }),
                  _vm.initialized
                    ? _c(
                        "div",
                        { staticClass: "mb-4" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Supported charge types* ")]
                          ),
                          _c("AlgoliaOptionsComponent", {
                            attrs: {
                              label: (option) => option.name,
                              index: _vm.ALGOLIA_INDEXES.chargeTypes,
                              "path-value": "uuid",
                            },
                            model: {
                              value: _vm.inputs.chargeTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "chargeTypes", $$v)
                              },
                              expression: "inputs.chargeTypes",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "label",
                    { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                    [_vm._v("Installation type options*")]
                  ),
                  _vm._l(_vm.values(_vm.INSTALLATION_TYPES), function (type) {
                    return _c("ui-radio", {
                      key: type,
                      staticClass: "d-block mb-2",
                      attrs: {
                        value: _vm.inputs.installationType,
                        option: type,
                        caption: _vm.sentenceCase(type),
                        name: "installationType",
                      },
                      on: {
                        changevalue: () => (_vm.inputs.installationType = type),
                      },
                    })
                  }),
                ],
                2
              ),
            ]
          ),
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "pl-2" },
              [
                _c("div", { staticClass: "mb-4" }, [
                  _c(
                    "label",
                    { staticClass: "d-block emobg-font-weight-semibold mb-1" },
                    [_vm._v(" Allocate charge post ")]
                  ),
                  _c(
                    "p",
                    { staticClass: "emobg-caption-1 emobg-color-ink-light" },
                    [
                      _vm._v(
                        " Select the next fields to allocate the charge post to a specific location. "
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    { staticClass: "emobg-caption-1 emobg-color-ink-light" },
                    [
                      _vm._v(
                        " Charge posts cannot be assigned to Geofence locations. "
                      ),
                    ]
                  ),
                ]),
                _vm.initialized
                  ? _c("MuiAlgoliaSelect", {
                      staticClass: "w-100 mb-4",
                      attrs: {
                        title: (result) => result.name,
                        filters: `id:${_vm.activeOperatorId} OR parent_cs_operator_id:${_vm.activeOperatorId}`,
                        index: _vm.ALGOLIA_INDEXES.csOperators,
                        label: "Operator*",
                        name: "operator",
                        "no-cache": "",
                        "path-value": "uuid",
                        placeholder: "Select an operator",
                      },
                      model: {
                        value: _vm.inputs.operatorUuid,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "operatorUuid", $$v)
                        },
                        expression: "inputs.operatorUuid",
                      },
                    })
                  : _c("ui-skeleton", { staticClass: "mb-4" }),
                _vm.initialized && _vm.citiesStatus.LOADED
                  ? _c("MuiSelect", {
                      staticClass: "w-100 mb-4",
                      attrs: {
                        options: _vm.map(_vm.citiesData, (city) => ({
                          label: city.name,
                          value: city.uuid,
                        })),
                        name: "city",
                        placeholder: "Select a city",
                        label: "City*",
                      },
                      model: {
                        value: _vm.inputs.cityUuid,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "cityUuid", $$v)
                        },
                        expression: "inputs.cityUuid",
                      },
                    })
                  : _vm._e(),
                _vm.citiesStatus.LOADING ? _c("ui-skeleton") : _vm._e(),
                _vm.initialized && _vm.citiesStatus.LOADED
                  ? _c("MuiAlgoliaSelect", {
                      staticClass: "w-100",
                      attrs: {
                        title: (result) => result.name,
                        filters: `city_uuid:${_vm.inputs.cityUuid} AND NOT type: geofence`,
                        disabled: !_vm.inputs.cityUuid,
                        index: _vm.ALGOLIA_INDEXES.locations,
                        label: "Location*",
                        name: "location",
                        "no-cache": "",
                        "path-value": "uuid",
                        placeholder: "Select a location",
                      },
                      model: {
                        value: _vm.inputs.locationUuid,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "locationUuid", $$v)
                        },
                        expression: "inputs.locationUuid",
                      },
                    })
                  : _c("ui-skeleton", { staticClass: "mb-4" }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    attrs: {
                      disabled:
                        !_vm.areRequiredFieldsFilled || _vm.hasSameValues,
                      loading: _vm.newChargePostStatus.LOADING,
                    },
                    on: { clickbutton: _vm.request },
                  },
                  [_vm._v(" Save ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }