import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS } from '@emobg/vue-base';
import { sentenceCase } from '@emobg/web-utils';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { FLEET_PERMISSIONS } from '../../../const/permissions';
import fleetRoutes from '../../../router/FleetRouterMap';

export function contentCells() {
  return [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: fleetRoutes.chargePosts.detail.setup,
          params: {
            chargePostUuid: result.uuid,
          },
        },
        linkPermissions: [FLEET_PERMISSIONS.viewCarsharingVehicles],
        text: result.id,
      }),
      minWidth: 100,
    },
    {
      attributeName: 'model.full_name',
      title: 'Model',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'city.name',
      title: 'City',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'location.name',
      title: 'Location',
      displayPriority: 1,
      minWidth: 200,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'active',
      title: 'Status',
      displayPriority: 1,
      minWidth: 100,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: result => ({
        text: result.active ? 'Active' : 'Inactive',
        color: result.active ? COLORS.success : COLORS.danger,
      }),
    },
    {
      attributeName: 'installation_type',
      title: 'Installation type',
      displayPriority: 1,
      minWidth: 150,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'serial_number',
      title: 'Serial number',
      displayPriority: 2,
      minWidth: 150,
    },
    {
      attributeName: 'operator.name',
      title: 'Operator',
      displayPriority: 2,
      minWidth: 150,
    },
  ];
}
