var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "ChargePostDetailView d-flex flex-column flex-fill",
        {
          "justify-content-center": _vm.isLoading,
        },
      ],
    },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { label: "Loading charge post..." } })
        : _vm.chargePost
        ? [
            _c("PageTabs", { attrs: { tabs: _vm.tabs } }),
            _c(
              "PageView",
              { attrs: { "is-tab-content": "" } },
              [
                _c("RouterView", {
                  on: { refreshChargePost: _vm.fetchChargePost },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }