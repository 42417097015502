<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ContentCellComponent, EditButton } from '@/components';
import ChargePostForm from '../../components/ChargePostForm';
import ChargePostStatusForm from '../../components/ChargePostStatusForm';

export default {
  name: 'ChargePostsDetailSetup',
  components: {
    EditButton,
    ContentCellComponent,
    ChargePostForm,
    ChargePostStatusForm,
  },
  data() {
    return {
      isLoading: false,
      isChargePostFormOpened: false,
      isChargePostStatusFormOpened: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.chargePosts, {
      chargePost: state => state.detail.data,
    }),
  },
  methods: {
    get,
    sentenceCase,
  },
};
</script>

<template>
  <div class="ChargePostDetailSetup">
    <h2 class="mb-2 emobg-font-weight-semibold">
      Charge post setup
    </h2>
    <ui-card :header="get(chargePost, 'model.fullName')">
      <ui-card
        header="General information"
        nested
      >
        <div class="row">
          <div class="col-10">
            <div class="row">
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="chargePost.id"
                  label="Id"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="get(chargePost, 'model.fullName')"
                  label="Model"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="chargePost.serialNumber"
                  label="Serial number"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="sentenceCase(chargePost.installationType)"
                  label="Installation type"
                />
              </div>
              <div class="col-12 mb-4">
                <ContentCellComponent label="Supported charge types">
                  <p
                    v-for="chargeType in chargePost.chargeTypes"
                    :key="chargeType.internalName"
                  >
                    {{ chargeType.name }}
                  </p>
                </ContentCellComponent>
              </div>
            </div>
          </div>
          <div class="col d-flex justify-content-end">
            <EditButton
              class="mb-4"
              @click="isChargePostFormOpened = true"
            />
          </div>
        </div>
      </ui-card>
      <hr class="emobg-border-top-1 emobg-border-color-ground-light mb-4">
      <ui-card
        header="Allocation information"
        nested
      >
        <div class="row">
          <div class="col-10">
            <div class="row">
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="get(chargePost, 'operator.name', FALLBACK_MESSAGE.dash)"
                  label="Operator"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="get(chargePost, 'city.name', FALLBACK_MESSAGE.dash)"
                  label="City"
                />
              </div>
              <div class="col-sm-6 col-md-3 mb-4">
                <ContentCellComponent
                  :value="get(chargePost, 'location.name', FALLBACK_MESSAGE.dash)"
                  label="Location"
                />
              </div>
            </div>
          </div>
        </div>
      </ui-card>
    </ui-card>
    <ui-card
      class="mt-3 d-block"
      header="Status"
    >
      <div class="row">
        <div class="col-10">
          <ContentCellComponent label="Charge post status">
            <ui-badge
              :color="chargePost.active ? COLORS.success : COLORS.danger"
              :text="chargePost.active ? 'Active' : 'Inactive'"
            />
          </ContentCellComponent>
        </div>
        <div class="col d-flex justify-content-end">
          <EditButton @click="isChargePostStatusFormOpened = true" />
        </div>
      </div>
    </ui-card>
    <ChargePostForm
      v-if="isChargePostFormOpened"
      :charge-post-uuid="chargePost.uuid"
      :callback="() => $emit('refreshChargePost')"
      @closeModal="isChargePostFormOpened = false"
      @modal-closed="isChargePostFormOpened = false"
    />
    <ChargePostStatusForm
      v-if="isChargePostStatusFormOpened"
      :charge-post="chargePost"
      :on-success="() => $emit('refreshChargePost')"
      @closeModal="isChargePostStatusFormOpened = false"
    />
  </div>
</template>
