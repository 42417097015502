var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ChargePostDetailSetup" },
    [
      _c("h2", { staticClass: "mb-2 emobg-font-weight-semibold" }, [
        _vm._v(" Charge post setup "),
      ]),
      _c(
        "ui-card",
        { attrs: { header: _vm.get(_vm.chargePost, "model.fullName") } },
        [
          _c(
            "ui-card",
            { attrs: { header: "General information", nested: "" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-10" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-md-3 mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: { value: _vm.chargePost.id, label: "Id" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-md-3 mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: _vm.get(_vm.chargePost, "model.fullName"),
                            label: "Model",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-md-3 mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: _vm.chargePost.serialNumber,
                            label: "Serial number",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-md-3 mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: _vm.sentenceCase(
                              _vm.chargePost.installationType
                            ),
                            label: "Installation type",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-4" },
                      [
                        _c(
                          "ContentCellComponent",
                          { attrs: { label: "Supported charge types" } },
                          _vm._l(
                            _vm.chargePost.chargeTypes,
                            function (chargeType) {
                              return _c("p", { key: chargeType.internalName }, [
                                _vm._v(" " + _vm._s(chargeType.name) + " "),
                              ])
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col d-flex justify-content-end" },
                  [
                    _c("EditButton", {
                      staticClass: "mb-4",
                      on: {
                        click: function ($event) {
                          _vm.isChargePostFormOpened = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("hr", {
            staticClass:
              "emobg-border-top-1 emobg-border-color-ground-light mb-4",
          }),
          _c(
            "ui-card",
            { attrs: { header: "Allocation information", nested: "" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-10" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-md-3 mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: _vm.get(
                              _vm.chargePost,
                              "operator.name",
                              _vm.FALLBACK_MESSAGE.dash
                            ),
                            label: "Operator",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-md-3 mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: _vm.get(
                              _vm.chargePost,
                              "city.name",
                              _vm.FALLBACK_MESSAGE.dash
                            ),
                            label: "City",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-md-3 mb-4" },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: _vm.get(
                              _vm.chargePost,
                              "location.name",
                              _vm.FALLBACK_MESSAGE.dash
                            ),
                            label: "Location",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "ui-card",
        { staticClass: "mt-3 d-block", attrs: { header: "Status" } },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-10" },
              [
                _c(
                  "ContentCellComponent",
                  { attrs: { label: "Charge post status" } },
                  [
                    _c("ui-badge", {
                      attrs: {
                        color: _vm.chargePost.active
                          ? _vm.COLORS.success
                          : _vm.COLORS.danger,
                        text: _vm.chargePost.active ? "Active" : "Inactive",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col d-flex justify-content-end" },
              [
                _c("EditButton", {
                  on: {
                    click: function ($event) {
                      _vm.isChargePostStatusFormOpened = true
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.isChargePostFormOpened
        ? _c("ChargePostForm", {
            attrs: {
              "charge-post-uuid": _vm.chargePost.uuid,
              callback: () => _vm.$emit("refreshChargePost"),
            },
            on: {
              closeModal: function ($event) {
                _vm.isChargePostFormOpened = false
              },
              "modal-closed": function ($event) {
                _vm.isChargePostFormOpened = false
              },
            },
          })
        : _vm._e(),
      _vm.isChargePostStatusFormOpened
        ? _c("ChargePostStatusForm", {
            attrs: {
              "charge-post": _vm.chargePost,
              "on-success": () => _vm.$emit("refreshChargePost"),
            },
            on: {
              closeModal: function ($event) {
                _vm.isChargePostStatusFormOpened = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }