import { sentenceCase } from '@emobg/web-utils';
export { contentCells } from './contentCells';

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Model',
      attributeName: 'model.full_name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Operator',
      attributeName: 'operator.name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'city.name',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Location',
      attributeName: 'location.name',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'active',
      transformValue: value => (value === 'true' ? 'Active' : 'Inactive'),
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Installation type',
      attributeName: 'installation_type',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Supported charge types',
      attributeName: 'charge_types.name',
    },
  },
];
