<script>
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapGetters } from 'vuex';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import {
  contentCells,
  facets,
} from './config/index';
import ChargePostForm from '../components/ChargePostForm';
import DeleteChargePost from '../components/DeleteChargePost';

export default {
  name: 'ChargePostsListView',
  components: {
    MuiAlgoliaList,
    PageView,
    ChargePostForm,
    DeleteChargePost,
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      isDeleteModalVisible: false,
      uuidToEdit: null,
      chargePost: null,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    const element = 'charge post';
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells();
    this.exportColumns = this.contentCells;
    this.facets = facets;
    this.itemsActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element }),
        method: ({ uuid }) => {
          this.uuidToEdit = uuid;
          this.isModalVisible = true;
        },
      },
      {
        label: `Delete ${element}`,
        type: 'danger',
        class: 'emobg-color-danger',
        method: chargePost => {
          this.chargePost = camelCaseKeys(chargePost);
          this.isDeleteModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
      this.uuidToEdit = null;
      this.chargePost = null;
    },
    onFormSuccess() {
      this.closeModal();
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.chargePosts, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <PageView
    class="ChargePostsListView"
    data-test-id="charge_posts_list-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Charge posts
      </h1>
      <ui-button
        data-test-id="create_charge_post-button"
        @clickbutton="isModalVisible = true"
      >
        Create new charge post
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="chargePosts"
        :filters="getOperatorFilter({ attribute: 'operator.id' })"
        :item-actions="itemsActions"
        :export-columns="exportColumns"
        :facets="facets"
        :labels="{
          searchTable: 'Search by Id, model, city...',
        }"
        :table-config="contentCells"
        :index="ALGOLIA_INDEXES.chargePosts"
        data-test-id="list"
        is-export-enabled
      />
    </div>

    <ChargePostForm
      v-if="isModalVisible"
      :charge-post-uuid="uuidToEdit"
      :callback="onFormSuccess"
      data-test-id="charge_post-form"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
    <DeleteChargePost
      v-if="isDeleteModalVisible"
      :charge-post="chargePost"
      :callback="onFormSuccess"
      data-test-id="delete_charge_post"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
  </PageView>
</template>
